import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

// local import
import * as styles from "../../styles/Homepage/Home.module.scss";
import "../../styles/Homepage/Home.scss";
import DemoButton from "../Common/DemoButton";
import BlockStorage from "./BlockStorage";
import { BlockStorageSm } from "./BlockStorage";
import Features from "./Features";
import Carousel from "../Common/Carousels";
import DemoLargeButton from "../Common/DemoLargeButton";

import BlogCard from "./BlogCard";
import InfraCarousal from "../Common/InfraCarousal";
import checkCircle from "../../assets/images/homepage/check_circle.svg";
import fastForward from "../../assets/images/homepage/fast_forward.svg";
import arrowCircleUp from "../../assets/images/homepage/arrow_circle_up.svg";
import addBox from "../../assets/images/homepage/add_box.svg";
import spa from "../../assets/images/homepage/spa.svg";
import hidesource from "../../assets/images/homepage/hide_source.svg";
import speed from "../../assets/images/homepage/speed.svg";
import fitnessCenter from "../../assets/images/homepage/fitness_center.svg";
import monetization_on from "../../assets/images/homepage/monetization_on.svg";
import alarm from "../../assets/images/homepage/alarm.svg";
import featureLines from "../../assets/images/homepage/feature-line.png";
import dashboardVideo from "../../assets/images/homepage/dashboard.mp4";

import blogOne from "../../assets/images/homepage/blog-one.png";
import blogTwo from "../../assets/images/homepage/blog-two.svg";
import blogThree from "../../assets/images/homepage/blog-three.svg";
import circel from "../../assets/images/about/circle.png";
import smallCircel from "../../assets/images/homepage/small-circle.svg";
import dashboard from "../../assets/images/homepage/dashboard.png";
// news image
import nasdaqLogo from "../../assets/images/homepage/nasdaq.png";
import cnbc from "../../assets/images/homepage/cnbc.png";
import techcrunsh from "../../assets/images/homepage/techcrunsh.png";
import forbes from "../../assets/images/homepage/forbes.png";
import techCrunchNews from "../../assets/images/homepage/techCrunchNews.png";
import cnbctvNews from "../../assets/images/homepage/cnbctvNews.png";
import forbesNews from "../../assets/images/homepage/forbesNews.png";
import nasdaqNews from "../../assets/images/homepage/nasdaqNews.png";
import Tick from "../../assets/images/homepage/tick.svg";
import DesktopPlatform from "../../assets/images/homepage/desktop_plat.png";
import MobilePlatform from "../../assets/images/homepage/mobile_plat.png";

const HomePage = () => {
  const [slideFeatureNo, setSliderFeatureNo] = useState(1);
  const [slideNo, setSlideNo] = useState(0);

  const onHover = (e) => {
    let currId = Number(e.target.getAttribute("data-id"));
    if (currId !== 0) {
      setSliderFeatureNo(currId);
      let cList = document.getElementsByClassName(
        `${styles["features__container__sliderbackground"]}`
      )[0];
      setTimeout(() => {
        cList.classList.add(
          `${styles[`${"features__container__slidermargin" + String(currId)}`]}`
        );
        for (let i = 1; i < 6; i++) {
          if (i !== currId) {
            cList.classList.remove(
              `${styles[`${"features__container__slidermargin" + String(i)}`]}`
            );
          }
        }
      }, 70);
    }
  };

  const onMouseEnter = (number) => {
    setSlideNo(number);
    let cList = document.getElementsByClassName(
      `${styles["storage__slider__linebar"]}`
    )[0];
    for (let i = 0; i < 3; i++) {
      if (i !== number) {
        setTimeout(() => {
          cList.classList.remove(
            `${styles[`${"storage__slider__linebar__margin" + String(i)}`]}`
          );
        }, 50);
      } else {
        cList.classList.add(
          `${styles[`${"storage__slider__linebar__margin" + slideNo}`]}`
        );
      }
    }
  };

  return (
    <>
     

        <section id="home-hero" className={styles["home"]}>
          <Container className={styles.home__container}>
            <Row className="justify-content-center">
              <Col lg={8} xs={12} className="text-center p-0">
                <div
                  className={`${styles["home__heading"]} justify-content-center`}
                >
                  <span>
                    <span className={styles.cloud_primary}>No-Ops&nbsp;</span>
                  </span>
                  <p>for Cloud Storage</p>
                </div>
                <div className={styles.hero_body}>
                  <span>
                    Transform your block storage with 3 clicks and deliver
                  </span>
                </div>
                <div
                  className={`${styles["home__hero_features"]} justify-content-center`}
                >
                  <div>
                    <img src={Tick} alt="tick" />
                    <span>99.999% Uptime</span>
                  </div>

                  <div>
                    <img src={Tick} alt="tick" />
                    <span>10X Performance</span>
                  </div>
                  <div>
                    <img src={Tick} alt="tick" />
                    <span>70% Cost Savings</span>
                  </div>
                  <div>
                    <img src={Tick} alt="tick" />
                    <span>No Code Changes</span>
                  </div>
                </div>
                <div className={styles["home__demobutton"]}>
                  <DemoLargeButton link="/book-demo" />
                </div>
                <div className={styles["home__works_logo"]}>
                  <img src={DesktopPlatform} alt={"platform"} className={styles["desktop_platform"]}/>
                  <img src={MobilePlatform} alt={"platform"} className={styles["mobilePlatform"]}/>
                </div>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={styles["customerlogo"]}>
          <Container className={styles["customerlogo__container"]}>
            <video autoPlay loop muted>
              <source src={dashboardVideo} type="video/mp4" />
            </video>
          </Container>
        </section>

        <section className={styles["storage"]}>
          <img
            src={circel}
            alt={"circle"}
            className={styles["storage__circel"]}
          />
          <img
            src={smallCircel}
            alt={"small-circel"}
            className={styles["storage__circel2"]}
          />
          <Container className={styles["storage__container"]}>
            <div className={styles["storage__containerdiv"]}>
              <Row className="justify-content-center">
                <Col lg={7} className="text-center">
                  <h2
                    className={`${styles["storage__sm_heading"]} d-block d-sm-none`}
                  >
                    Supercharge your cloud block storage
                  </h2>
                  <span
                    className={`${styles["storage__sm_subheading"]} d-block d-sm-none`}
                  >
                    Instantly realize cheaper, faster, and more performant data.
                  </span>
                  <div
                    className={`${styles["storage__sm_button"]} d-block d-sm-none`}
                  >
                    <DemoButton text={"Find out how"} />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col className={styles["storage__col2"]}>
                  <BlockStorageSm />
                  <div className="d-none d-md-none d-lg-block">
                    <BlockStorage
                      slideNo={slideNo}
                      onMouseEnter={onMouseEnter}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className={`${styles["migrate"]}`}>
          <Container className={styles["migrate__container"]}>
            <Row>
              <Col
                lg={5}
                md={5}
                sm={12}
                className={`${styles["migrate__col1"]} text-center text-sm-start order-2 order-sm-0`}
              >
                <h2>Move your block storage data to the cloud</h2>
                <span>
                  Migrate any app to the cloud and realize faster, reliable, and
                  cheaper block storage with zero application code changes or
                  re-architecture.
                </span>
                <div className={styles["migrate__col1__btn"]}>
                  <DemoButton combo={true} />
                </div>
              </Col>
              <Col lg={7} md={7} sm={12} className="pe-0 ps-0">
                <img
                  className="float-right ms-auto d-none d-sm-block"
                  src={dashboard}
                  alt={dashboard}
                />
                <img
                  className="float-right ms-auto d-block d-sm-none w-100"
                  src={dashboard}
                  alt={"dashboard"}
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`${styles["features"]}`}>
          <Container className={styles["features__container"]}>
            <Row>
              <Col sm={12}>
                <div
                  className={`${styles["features__header"]} d-none d-sm-flex`}
                >
                  <h3>&nbsp;</h3>
                  <h3>Before Lucidity</h3>
                  <h3>After Lucidity</h3>
                </div>
                <img
                  src={featureLines}
                  alt="line"
                  className={styles["features__container__line"]}
                />
                <div
                  className={`${
                    styles["features__container__sliderbackground"]
                  } 
                        ${styles["features__container__slidermargin"]}
                        ${
                          slideFeatureNo === 0
                            ? styles[
                                "features__container__slider_initial_margin"
                              ]
                            : ""
                        }
                      `}
                >
                  <div className={styles["features__container__bar"]}></div>
                </div>
                <Features
                  dataTag={1}
                  sideImgSrc={hidesource}
                  heading={"Zero Downtime"}
                  withoutLucidity={"Once in a quarter"}
                  withLucidity={"Nil"}
                  imgSrc={checkCircle}
                  active={slideFeatureNo === 1 ? "active" : ""}
                  classes={
                    slideFeatureNo === 1
                      ? `features__slider__opacity9`
                      : `features__slider__opacity${
                          slideFeatureNo >= 3 ? 1 : 8 - slideFeatureNo
                        }`
                  }
                  mouseEnter={onHover}
                />
                <Features
                  dataTag={2}
                  sideImgSrc={speed}
                  heading={"3X faster"}
                  withoutLucidity={"3-5 ms"}
                  withLucidity={"1-2 ms"}
                  imgSrc={fastForward}
                  active={slideFeatureNo === 2 ? "active" : ""}
                  classes={
                    slideFeatureNo === 2
                      ? `features__slider__opacity8`
                      : `features__slider__opacity${String(9 - slideFeatureNo)}`
                  }
                  mouseEnter={onHover}
                />
                <Features
                  dataTag={3}
                  sideImgSrc={fitnessCenter}
                  heading={"10X capacity"}
                  withoutLucidity={"3000 IOPS"}
                  withLucidity={"30,000 IOPS"}
                  imgSrc={arrowCircleUp}
                  active={slideFeatureNo === 3 ? "active" : ""}
                  classes={
                    slideFeatureNo === 3
                      ? `features__slider__opacity7`
                      : `features__slider__opacity${String(8 - slideFeatureNo)}`
                  }
                  mouseEnter={onHover}
                />
                <Features
                  dataTag={4}
                  sideImgSrc={monetization_on}
                  heading={"70% costs saved"}
                  withoutLucidity={"$ 2M / yr"}
                  withLucidity={"$600K / yr"}
                  imgSrc={addBox}
                  active={slideFeatureNo === 4 ? "active" : ""}
                  classes={
                    slideFeatureNo === 4
                      ? `features__slider__opacity6`
                      : `features__slider__opacity${String(7 - slideFeatureNo)}`
                  }
                  mouseEnter={onHover}
                />
                <Features
                  dataTag={5}
                  sideImgSrc={alarm}
                  heading={"DevOps Time"}
                  withoutLucidity={"1-2 FTE"}
                  withLucidity={"Nil"}
                  imgSrc={spa}
                  active={slideFeatureNo === 5 ? "active" : ""}
                  classes={"features__slider__opacity55"}
                  mouseEnter={onHover}
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section className={styles["home_team_love"]}>
          <InfraCarousal />
        </section>

        {/* Lucidity News */}
        <section className={`${styles["blog"]}`}>
          <Container className={styles["blog__container"]}>
            <Row className="pe-0 position-relative">
              <Col lg={4} className={styles["blog__col1"]}>
                <h2 className={styles["blog__heading"]}>
                  Lucidity in the news
                </h2>
                <p className={styles["blog__left_subheading"]}>
                  The latest news and mentions about Lucidity, our partners, and
                  the latest innovations in “NoOps” cloud experience.
                </p>
              </Col>
              <Col lg={8} className={`pe-0 ${styles["blog__col2"]}`}>
                <Carousel
                  slideCount={4}
                  slideWidth={348}
                  slideTime={2800}
                  smWidth={250}
                  classNames1={styles["blog__carousel"]}
                  classNames2={styles["news_slider_list"]}
                  isScale={true}
                >
                  <BlogCard
                    heading={`Lucidity keeps cloud block storage neat, tidy and cost-efficient`}
                    subheading="TechCrunch captures interesting facets in today’s cloud space, and how firms are already leveraging Lucidity to adopt the vision of “NoOps for cloud storage”."
                    imgSrc={techCrunchNews}
                    newsLogo={techcrunsh}
                    href="https://techcrunch.com/2022/09/07/lucidity-keeps-cloud-block-storage-neat-tidy-and-cost-efficient"
                  />
                  <BlogCard
                    heading={`Realizing The Potential Of Cloud Computing With Lucidity`}
                    subheading="Forbes talks on how businesses today are missing out on many of the supposed benefits of cloud shift, and how Lucidity can help."
                    imgSrc={forbesNews}
                    newsLogo={forbes}
                    className="forbs_logo"
                    href="https://www.forbes.com/sites/davidprosser/2022/09/08/realising-the-potential-of-cloud-computing-with-lucidity/?sh=669c37003bf2"
                  />
                  <BlogCard
                    heading={`Lucidity goes live in Times Square`}
                    subheading="We just closed our seed funding round for $5.3 Mil!!! It brings us closer to our goal: To make enterprises truly cloud-ready."
                    imgSrc={nasdaqNews}
                    newsLogo={nasdaqLogo}
                    href="https://www.linkedin.com/feed/update/urn:li:activity:6973946945656549376"
                  />
                  <BlogCard
                    heading={`Lucidity just got covered in Young Turks`}
                    subheading="Watch here as our co-founder, Nitin Bhadauria discusses today's industry state, Lucidity, our future expansion plans, and more, in India's longest-running startups show, Young Turks"
                    imgSrc={cnbctvNews}
                    newsLogo={cnbc}
                    href="https://www.cnbctv18.com/videos/startup/lucidity-to-utilise-53-million-fundraise-for-product-development-business-expansion-14717191.htm"
                  />
                </Carousel>
              </Col>
            </Row>
          </Container>
        </section>

        <section className={`${styles["blog"]} pt-0 pt-sm-4`}>
          <Container className={styles["blog__container"]}>
            <Row className="pe-0 position-relative">
              <Col lg={4} className={styles["blog__col1"]}>
                <h2 className={styles["blog__heading"]}>What’s new</h2>
              </Col>
              <Col lg={8} className={`pe-0 ${styles["blog__col2"]}`}>
                <Carousel
                  slideCount={3}
                  slideWidth={348}
                  slideTime={3000}
                  smWidth={250}
                  classNames1={styles["blog__carousel"]}
                  classNames2={styles["slider_list"]}
                  isScale={true}
                >
                  <BlogCard
                    heading={`The "Data Is Exploding" Case Study You'll Never Forget`}
                    author={"Lavanya Snigdha"}
                    subheading={`175 zettabytes. That is the amount of data the International Data Corporation (IDC) predicts will be present in the world's datasphere by 2025...`}
                    type={"Blog"}
                    readtime={"5 min read"}
                    imgSrc={blogOne}
                    href={
                      "/blog/the-data-is-exploding-case-study-youll-never-forget"
                    }
                  />
                  <BlogCard
                    heading={"AWS FSx Integrates with NetApp ONTAP"}
                    author={"Lavanya Snigdha"}
                    subheading={`It is no secret that NetApp ONTAP is the reigning leader of data storage management services. With a valuation of over $8 Billion, NetApp ONTAP is the go-to data management suite…..`}
                    type={"Blog"}
                    readtime={"3 min read"}
                    imgSrc={blogTwo}
                    href={
                      "/blog/aws-fsx-integrates-with-netapp-ontap"
                    }
                  />
                  <BlogCard
                    heading={
                      "New kid on the block: CloudFlare introduces egress-free R2 Object Storage”"
                    }
                    author={"Lavanya Snigdha"}
                    subheading={`While most devs swallow the bitter pill when they need to get files out of regularly accessed storages like S3, having to pay egress for object storage seems a little counter-intuitive.…..`}
                    type={"Blog"}
                    readtime={"3 min read"}
                    imgSrc={blogThree}
                    href={
                      "/blog/cloudflare-introduces-egress-free-r2-object-storage"
                    }
                  />
                </Carousel>
              </Col>
            </Row>
          </Container>
        </section>
    </>
  );
};

export default HomePage;
