import React from 'react'

// local import
import * as styles from "./InfraCarousal.module.scss"
import * as homeStyles from "../../../styles/Homepage/Home.module.scss"

const CarouselCard = ({heading, subheading, author, repu, leftImg}) => {
  return (
    <>
    <div className={`${styles['teamlove__carousel__card']} ${homeStyles['home_team_love__carousel__card']}`}>
        <p>{heading}</p>
        <p>{subheading}</p>
        <div className={`${styles['teamlove__carousel__card__bottom']} ${homeStyles['home_team_love__carousel__card__bottom']}`}>
            <p>{author}</p>
            <p>{repu}</p>
            <img src={leftImg} alt={leftImg} />
        </div>
        
    </div>
    </>
  )
}

export default CarouselCard;