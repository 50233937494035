import React from "react";
import { Accordion, Row, Col, Card } from "react-bootstrap";

// local
import * as styles from "../../styles/Homepage/Home.module.scss";
import DemoButton from "../Common/DemoButton";

import storageLine from "../../assets/images/homepage/storage-line.svg";
import payments from "../../assets/images/homepage/payments.svg";
import bolt from "../../assets/images/homepage/bolt.svg";
import key from "../../assets/images/homepage/key.svg";
import downArrow from "../../assets/images/homepage/down-arrow.svg";
import cloud from "../../assets/images/homepage/cloud.svg";
import arrow from "../../assets/images/homepage/arrow.png";
import monetize from "../../assets/images/homepage/monetization.png";

export const BlockStorage = ({ slideNo, onMouseEnter }) => {
  const AccordionHeader = ({ children, display, className }) => {
    return (
      <div className={`d-flex align-items-baseline ${className}`}>
        <h2>{children}</h2>
        <img src={downArrow} alt="arrow" className={`${display} ps-3`} />
      </div>
    );
  };

  return (
    <>
      <div className={styles["storage__wrapper"]} id="trust">
        <img
          src={storageLine}
          alt="storage"
          className={styles["storage__wrapper__line"]}
        />
        <Row className="justify-content-center">
          <Col
            lg={10}
            md={10}
            className={`offset-lg-2 offset-md-2 ${styles["storage__wrapper__headings"]}`}
          >
            <h2>Supercharge your cloud block storage</h2>
            <span>
              Instantly realize cheaper, faster, and more performant data.
            </span>
            <p>
              Lucidity gives you the confidence to migrate your critical block
              storage data workloads to the cloud, so you can quickly save costs
              and experience faster data with no code changes.{" "}
            </p>

            <DemoButton text={"Find out how"} />
          </Col>
        </Row>

        <div className={styles["storage__slider"]}>
          <div
            className={`${styles["storage__slider__linebar"]} ${
              styles[`storage__slider__linebar__margin${slideNo}`]
            }`}
          ></div>

          <Row className="justify-content-center">
            <Col
              lg={10}
              md={10}
              className={`offset-lg-2 offset-md-2 ${styles["storage__wrapper__headings"]}`}
            >
              <Accordion
                defaultActiveKey="0"
                activeKey={String(slideNo)}
                className={styles["storage__slider__accordion"]}
              >
                <Card
                  className={styles["storage__slider__accordion__card"]}
                  onMouseEnter={() => onMouseEnter(0)}
                >
                  <Card.Header
                    className={
                      styles["storage__slider__accordion__card__header"]
                    }
                  >
                    <AccordionHeader
                      display={`${slideNo === 0 ? "d-block" : "d-none"}`}
                      className={`${
                        slideNo === 0
                          ? `${styles["storage__slider__accordion__card__header__refade"]}`
                          : ""
                      }`}
                    >
                      Cloud-Native Unlocked
                    </AccordionHeader>
                    <div
                      className={`${styles["storage__slider__key"]}`}
                      onMouseEnter={() => onMouseEnter(0)}
                    >
                      <img src={key} alt={"key"} />
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body
                      className={
                        styles["storage__slider__accordion__card__body"]
                      }
                    >
                      <h3>No-Code Multi-Cloud Block Storage Management</h3>
                      <span>
                        Unlock cloud-native functionality with zero code changes
                      </span>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card
                  className={styles["storage__slider__accordion__card"]}
                  onMouseEnter={() => onMouseEnter(1)}
                >
                  <Card.Header
                    className={
                      styles["storage__slider__accordion__card__header"]
                    }
                  >
                    <AccordionHeader
                      display={`${slideNo === 1 ? "d-block" : "d-none"}`}
                      className={`${
                        slideNo === 1
                          ? `${styles["storage__slider__accordion__card__header__refade"]}`
                          : ""
                      }`}
                    >
                      Data Acceleration
                    </AccordionHeader>
                    <div
                      className={`${styles["storage__slider__bolt"]}`}
                      onMouseEnter={() => onMouseEnter(1)}
                    >
                      <img src={bolt} alt={"bolt"} />
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body
                      className={
                        styles["storage__slider__accordion__card__body"]
                      }
                    >
                      <h3>10x Performance (IOPS) & 30% Faster Applications</h3>
                      <span>
                        Achieve high throughput and low latency with your cloud
                        block storage
                      </span>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card
                  className={styles["storage__slider__accordion__card"]}
                  onMouseEnter={() => onMouseEnter(2)}
                >
                  <Card.Header
                    className={
                      styles["storage__slider__accordion__card__header"]
                    }
                  >
                    <AccordionHeader
                      display={`${slideNo === 2 ? "d-block" : "d-none"}`}
                      className={`${
                        slideNo === 2
                          ? `${styles["storage__slider__accordion__card__header__refade"]}`
                          : ""
                      }`}
                    >
                      Cost Optimization
                    </AccordionHeader>
                    <div
                      className={`${styles["storage__slider__payments"]}`}
                      onMouseEnter={() => onMouseEnter(2)}
                    >
                      <img src={payments} alt={"payments"} />
                    </div>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body
                      className={
                        styles["storage__slider__accordion__card__body"]
                      }
                    >
                      <h3>70% Reduction in cost</h3>
                      <span>
                        Quickly eliminate over-provisioning and improve storage
                        space efficiency
                      </span>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <img
                  src={cloud}
                  alt="cloud"
                  className={`${
                    slideNo === 0 ? `img1animate1` : `img1animate2`
                  }  img1`}
                />
                <img
                  src={arrow}
                  alt="cloud"
                  className={`${
                    slideNo === 1 ? `img2animate1` : `img2animate2`
                  }  img2`}
                />
                <img
                  src={monetize}
                  alt="cloud"
                  className={`${
                    slideNo === 2 ? `img3animate1` : `img3animate2`
                  }  img3`}
                />
              </Accordion>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export const BlockStorageSm = () => {
  return (
    <>
      <div className={styles["storage__sm_wrapper"]}>
        <div className={styles["storage__sm_wrapper__image"]}>
          <img src={key} alt="key" />
        </div>
        <h4>Cloud-Native Unlocked</h4>
        <p>No-Code Multi-Cloud Block Storage Management</p>
        <p>Unlock cloud-native functionality with zero code changes</p>
      </div>
      <div className={styles["storage__sm_wrapper"]}>
        <div className={styles["storage__sm_wrapper__image"]}>
          <img src={bolt} alt="bolt" />
        </div>
        <h4>Data Acceleration</h4>
        <p>10x Performance (IOPS) & 30% Faster Applications</p>
        <p>
          Achieve high throughput and low latency with your cloud block storage
        </p>
      </div>
      <div className={styles["storage__sm_wrapper"]}>
        <div className={styles["storage__sm_wrapper__image"]}>
          <img src={payments} alt="payments" />
        </div>
        <h4>Cost Optimization</h4>
        <p>70% Reduction in cost</p>
        <p>
          Quickly eliminate over-provisioning and improve storage space
          efficiency
        </p>
      </div>
    </>
  );
};

export default BlockStorage;
