import React from 'react'
import { Row, Col, Container } from "react-bootstrap"

import * as styles from "./InfraCarousal.module.scss"
import * as homeStyles from "../../../styles/Homepage/Home.module.scss"
import Carousel from '../Carousels'
import CarouselCard from './CarouselCard'
import teamLoveLine from '../../../assets/images/homepage/teamlove-line.png'
import halfTriangle from '../../../assets/images/common/half_triangle.svg'
import triangle from '../../../assets/images/common/triangle.svg'
import circel from '../../../assets/images/about/circle.png'

const InfraCarousal = () => {
  return (
    <>
      <div className={`${styles['teamlove']}`}>
        <Container className={styles['teamlove__container']}>
          <Row className='pe-0 position-relative'>
            <Col lg={4} className={styles['teamlove__col1']}>
              <h2 className={styles['teamlove__heading']}>Infrastructure Teams Love Us</h2>
            </Col>
            <Col lg={8} className={`${styles['teamlove__col2']} pe-0`}>
              <img src={teamLoveLine} alt={teamLoveLine} className={`${styles['teamlove__line']} ${homeStyles['home_team_love__line']}`} />
              <img src={circel} alt={'circle'} className={styles['teamlove__circel']} />
              <div className={styles['teamlove__shortline']}></div>
              <Carousel slideCount={4} slideWidth={348} slideTime={3000} smWidth={250}
                isScale={true} classNames2={styles['slider_list']} classNames1={homeStyles['home_team_love__carousel__inner']}>
                <CarouselCard
                  heading={'It’s incredible'}
                  subheading={`“Lucidity is the first in the space of NoOps and addresses some important challenges 
                  for us in capacity management, cost and effort optimization.”`}
                  author={'Jay Dhakar - Cloud Architect'}
                  repu={'SpartanNash'}
                  leftImg={halfTriangle}
                />
                <CarouselCard
                  heading={'Simple and Amazing'}
                  subheading={`“Lucidity will be able to give us about 80% of the disk optimization, it will reduce our DevOps 
                  requirements for managing EBS volumes, and give us huge cost optimization”`}
                  author={'Kunal Dawn - VP, Engineering'}
                  repu={'Bobble.ai'}
                  leftImg={triangle}
                />
                {/* <CarouselCard
                  heading={'Zero effort implementation'}
                  subheading={`“Lucidity addresses the key concern of managing the storage dynamically or 
                  intelligently in the cloud.”`}
                  author={'Rakesh Singh'}
                  repu={'Founder, BeyondCXGroup'}
                  leftImg={halfTriangle}
                /> */}
                <CarouselCard
                  heading={'We saved so much!'}
                  subheading={`“Lucidity has a pretty unique solution that truly allows auto-scale of cloud storage, with no manual
                   intervention and no downtime, reducing storage costs by 70%.”`}
                  author={'Joan Kuehl - Ex CIO'}
                  repu={'Elevate Travelers'}
                  leftImg={halfTriangle}
                />
              </Carousel>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  )
}

export default InfraCarousal