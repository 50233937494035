import React from 'react'

// local
import * as styles from "../../styles/Homepage/Home.module.scss";

const Features = ({ dataTag, sideImgSrc, heading, withLucidity, withoutLucidity, imgSrc, active, classes, mouseEnter }) => {
  return (
    <>
      <div data-id={dataTag} 
      className={`${styles['features__slider']} ${active !== 'active' ? `${styles[classes]}` : `${styles[`features__slider__${active}`]}`}`}
      onMouseEnter={mouseEnter}>
        <div className={styles['features__slider__sideimg']} onTouchStart={mouseEnter}>
          <img data-id={dataTag} src={sideImgSrc} alt={sideImgSrc} />
        </div>
        <span data-id={dataTag}  className={styles['features__slider__feature_name']} onTouchStart={mouseEnter}>{heading}</span>
        <span className={styles['features__slider__without']}>
          {withoutLucidity}
        </span>
        <span className={styles['features__slider__with']}>
          <img src={imgSrc} alt={imgSrc} />
          {withLucidity}
        </span>
        <div className={`${styles['features__slider__sm_content']} ${active !== 'active' ? 
         `${styles[`features__slider__sm_content__nonactive`]}` : `${styles[`features__slider__sm_content__${active}`]}`}`}>
          <div className={`${styles['features__slider__sm_content__without']}`}>
            <p>Without Lucidity</p>
            <p>{withoutLucidity}</p>
          </div>
          <div className={`${styles['features__slider__sm_content__with']}`}>
            <p>With Lucidity</p>
            <div className='d-flex gap-2'>
              <img src={imgSrc} alt={imgSrc} />
              <p>{withLucidity}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Features