import React, { useEffect } from "react";

// local
import Layout from "../components/Common/Layout";
import Seo from "../components/Common/Seo";
import HomePage from "../components/HomePage";

const IndexPage = () => {
  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = "auto";
  }, []);
  console.log("Check sitemap deployement");
  return (
    <Layout>
      <Seo
        title="Lucidity | Transform your block storage with 3-click"
        description={`With Lucidity, Supercharge your block storage and instantly realise 70% cost savings, 99.999% uptime, 10X performance, all with just one click.`}
        canonicalUrl="https://www.lucidity.cloud"
      />
      <HomePage />
    </Layout>
  );
};

export default IndexPage;
